import React from 'react';
import './header.css';


function Header() {
    return (
        <header id='header'>
            <div className="container header__container">
                <h1 >Henry Nguyen</h1>

            </div>
        </header>
    )

}

export default Header;