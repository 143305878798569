
import React from 'react';

import Main from './components/main';
import './App.css';
// import './background.css';

function App() {

  return (
    <Main />
  );
}

export default App;
